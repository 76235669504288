import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Dropdown,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../../common/Loader";
import NoData from "../../../common/NoData";
import { Translate } from "../../../Enums/Tranlate";
import AddTeamModal from "./AddTeamModal";
import { useNavigate } from "react-router-dom";
import TeamService from "../../../../services/TeamService";
import DeleteModal from "../../../common/DeleteModal";
import './style.scss'

const Teams = () => {
    const [data, setData] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [item, setItem] = useState({})
    const [hasData, setHasData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    const navigate = useNavigate()
    const teamService = new TeamService()

    useEffect(()=> {
      setLoading(true)
      teamService.getList().then(res=>{
        if(res?.status){
          if(!!res?.data?.data?.data?.length){
            setData(res?.data?.data?.data)
            setHasData(1)
          } else {
            setData([])
            setHasData(0)
          }
        }
        setLoading(false)
      }).catch(()=> setLoading(false))
    } ,[shouldUpdate])

  return (
    <Fragment>
      {isExist('owner') && <Card className="mb-3">
        <Card.Body className="d-flex justify-content-between p-3 align-items-center">
          <div className="input-group w-50"></div>
          <Button variant="primary" className='me-2 h-75' onClick={()=> { 
              setItem({})
              setAddModal(true) }}>
              <i className="la la-plus mx-1"></i>
              {Translate[lang]?.add} {Translate[lang].team}
          </Button>
        </Card.Body >
      </Card>}
      
      <div>
        <div className={`${hasData === 0 && 'text-center'} `}>
          {loading && <Card style={{height: '300px'}}>
            <Card.Body>
              <Loader />
            </Card.Body>
          </Card>}

          {(hasData === 1 && !loading) && <Row className="mt-5">
              {data?.map((team, index) => {
                if((isExist('owner') || (Auth?.admin?.id === team?.user_id))){
                  return <Col md={4} key={index} onClick={() => navigate(`/team/${team?.id}`, {state: team?.id})} className="cursor-pointer mb-3">
                    <Card>
                        <img 
                          src={team?.image} 
                          alt='team' 
                          className="w-100" 
                          style={{
                            borderRadius: '10px 10px 0 0'
                          }}
                          height={200} 
                        />
                        <h2 className="text-center my-4">{team?.name}</h2>
                        <Dropdown 
                          className="position-absolute" 
                          style={{top: '8px', right: '8px'}}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          <Dropdown.Toggle className="sharp i-false btn-light">
                            <i className="la la-ellipsis-v text-black" style={{ fontSize: "27px" }}></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>{
                                setItem(team)
                                setAddModal(true)
                              }}
                            >
                              {Translate[lang]?.edit}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setDeleteModal(true)}>
                              {Translate[lang]?.delete}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                    </Card>
                  </Col>
                } else {
                  return
                }
              })}
          </Row>}

          {(hasData === 0 && !loading) && <NoData />}

        </div>
      </div>

      {addModal && 
        <AddTeamModal
          item={item}
          addModal={addModal} 
          setShouldUpdate={setShouldUpdate}
          setAddModal={()=> setAddModal(false)}
      />}
      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          titleMsg={item?.name}
          deletedItem={item}
          modelService={teamService}
          setShouldUpdate={setShouldUpdate}
          onCloseModal={setDeleteModal}
        />
      )}
    </Fragment>
  );
};

export default Teams;
