import { useState } from "react";
import { useSelector } from "react-redux";
import { Translate } from "../../../../Enums/Tranlate";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";

const Team = () =>{
    const location = useLocation()
    const id = location?.state
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const navigate = useNavigate()
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    const sectionsTab = ['goals', 'works', 'gallery', 'members', 'contact_us']

    return(<Row>
        {sectionsTab?.map((section, index) => {
            return <Col 
                md={3} 
                key={index} 
                className="cursor-pointer"
                onClick={()=> navigate(`/team/${id}/${section}`, { state: {id: id, type: section} })}
            >
                <Card style={{borderRadius: '8px'}}>
                    <Card.Body className="px-0">
                        <h2 className="text-center m-0 py-5">{Translate[lang][section]}</h2>
                    </Card.Body>
                </Card>
            </Col>
        })}
    </Row>)
}
export default Team;