import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Pages
import Error404 from "./common/Error404";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";

import Admins from "./pages/Admin";
import AddAdmin from "./pages/Admin/AddAdmin";
import Banners from "./pages/Banners";
import Home from "./pages/Dashboard";
import SocialMedia from "./pages/Setting/SocialMedia";
import Permission from "./pages/Rules";
import Profile from "./pages/Profile";
import StaticPages from "./pages/Setting/StaticPages";
import Static from "./pages/Setting/StaticPages/Static";
import WebsiteBanners from "./pages/Website/Banners";
import Activities from "./pages/Website/Activities"
import News from "./pages/Website/News"
import ContactUs from "./pages/Website/ContactUs";
import Notification from "./pages/Website/Notification";
import AddNotification from "./pages/Website/Notification/AddNotification";
import About from "./pages/Website/About/About";
import Gallery from "./pages/Website/Gallery";
import Teams from "./pages/Website/Teams";
import Team from "./pages/Website/Teams/Team";
import Goals from "./pages/Website/Teams/Team/Goals";
import Works from "./pages/Website/Teams/Team/Works";
import TeamGallery from "./pages/Website/Teams/Team/Gallery";
import ContactTeam from "./pages/Website/Teams/Team/ContactUs";
import Members from "./pages/Website/Teams/Team/Members";

const Markup = () => {
  const allroutes = [
    // Home
    { url: "", component: <Home /> },

    // Admins
    { url: "admins", component: <Admins /> },
    { url: "admins/add-admins", component: <AddAdmin /> },
    { url: "admins/edit-admin/:id/:name", component: <AddAdmin /> },

    // Rules
    { url: "rules", component: <Permission /> },
    { url: "rules/:id", component: <Permission /> },

    // Banners
    { url: "banners", component: <Banners /> },

    // Website
    { url: "website-banners", component: <WebsiteBanners />},
    { url: "activities", component: <Activities /> },
    { url: "news", component: <News /> },
    { url: "contact-us", component: <ContactUs /> },
    { url: "notification", component: <Notification /> },
    { url: "notification/add-notification", component: <AddNotification /> },
    { url: "about", component: <About /> },
    { url: "gallery", component: <Gallery /> },
    { url: "teams", component: <Teams /> },
    { url: "team/:id", component: <Team /> },
    { url: "team/:id/goals", component: <Goals /> },
    { url: "team/:id/members", component: <Members /> },
    { url: "team/:id/works", component: <Works /> },
    { url: "team/:id/gallery", component: <TeamGallery /> },
    { url: "team/:id/contact_us", component: <ContactTeam /> },

    //Setting
    { url: "social", component: <SocialMedia /> },
    // { url: "pages", component: <StaticPages /> },
    { url: "privacy", component: <Static /> },
    // { url: "faqs", component: <Static /> },

    //Profile
    { url: "profile", component: <Profile /> },

    // Error
    { url: "*", component: <Error404 /> },
  ];

  return (
    <>
      <Routes>
        <Route path="page-error-404" element={<Error404 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  return (
    <div id="main-wrapper" className={`show `}>
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
