import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Translate } from "../../../../../Enums/Tranlate";
import { useLocation } from "react-router-dom";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import Loader from "../../../../../common/Loader";
import NoData from "../../../../../common/NoData";
import AddWorksModal from "./AddWorksModal";
import CardItem from "./CardItem";
import TeamService from "../../../../../../services/TeamService";

const Works = () =>{
    const location = useLocation()
    const id = location?.state?.id
    const [data, setData] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [item, setItem] = useState({})
    const [hasData, setHasData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const teamService = new TeamService()
    const Auth = useSelector(state=> state.auth?.auth)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)

    useEffect(()=>{
      setLoading(true)
      teamService.getItemList('work', id).then(res=> {
        if(res?.status){
            if(!!res?.data?.data?.data?.length){
                setHasData(1)
                setData(res?.data?.data?.data)
            } else {
                setData([])
                setHasData(0)
            }
        }
        setLoading(false)
      }).catch(()=> setLoading(false))
    }, [shouldUpdate])
  return (
    <>
      <Card className="mb-3">
        <Card.Body className="d-flex justify-content-between p-3 align-items-center">
          <div className="input-group w-50">
            {/* <input 
                type="text" 
                style={{borderRadius: '8px',
                color: 'initial',
                padding: '18px 16px'}}
                className="form-control"
                placeholder={`${Translate[lang]?.search_by} I.D, ${Translate[lang]?.name}`}
                value={search}
                onChange={e=> setSearch(e.target.value)} 
            />
            <div className="flaticon-381-search-2"
              style={{position: 'absolute',zIndex:'99', right: lang === 'en' && '16px', left: lang === 'ar' && '16px', top: '50%', transform: 'translate(0, -50%)'}}
            ></div> */}
          </div>
            <Button variant="primary" className='me-2 h-75' onClick={()=> { 
              setItem({})
              setAddModal(true) }}>
              <i className="la la-plus mx-1"></i>
              {Translate[lang]?.add} {Translate[lang]?.works}
          </Button>
        </Card.Body>
      </Card>
      
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body className={`${hasData === 0 && 'text-center'} `}>
                {loading && <div style={{height: '300px'}}>
                <Loader />
              </div>}
              {(hasData === 1 && !loading) && <Table responsive>
                <thead>
                  <tr className='text-center'>
                    <th>
                      <strong>I.D</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.image}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.title}</strong>
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="table-body">
                    {data?.map((item, index) =>{
                        return <CardItem
                            index= {index}
                            key= {index}
                            item={item}
                            setItem={setItem}
                            teamId={id}
                            setAddModal={setAddModal}
                            setShouldUpdate={setShouldUpdate}
                        />
                    })}
                </tbody>
              </Table>}
              {hasData === 0 && <NoData />}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {addModal && 
        <AddWorksModal
          item={item} 
          addModal={addModal} 
          id={id}
          setShouldUpdate={setShouldUpdate}
          setAddModal={()=> setAddModal(false)}
      />}
    </>
  );
}
export default Works;