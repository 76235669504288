import { Button, Card, Col, Row } from "react-bootstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'draft-js/dist/Draft.css';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Loader from "../../../common/Loader";
import { Translate } from "../../../Enums/Tranlate";
import uploadImg from '../../../../images/upload-img.png'
import BaseService from "../../../../services/BaseService";
import AboutService from "../../../../services/AboutService";

const About = () =>{
    const [formData, setFormData] = useState({
        establishment_img: {src: "", loading: false},
        vision_img: {src: "", loading: false},
        goals_img: {src: "", loading: false},
        establishment_description: EditorState.createEmpty(),
        vision_description: EditorState.createEmpty(),
        goals_description: EditorState.createEmpty(),
        establishment_id: "",
        vision_id: "",
        goals_id: "",
    })
    const [addEstablishment, setAddEstablishment] = useState(false)
    const [addVision, setAddVision] = useState(false)
    const [addGoals, setAddGoals] = useState(false)
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    const [loading, setLoading] = useState()
    const [submitLoading, setSubmitLoading] = useState(false)
    const aboutService = new AboutService()

    useEffect(()=>{
        setLoading(true)
        let establishmentReq = aboutService.getList({type: 'establishment'})
        let visionReq = aboutService.getList({type: 'vision'})
        let goalsReq = aboutService.getList({type: 'goals'})

        setLoading(true)
        let info = {}
        Promise.all([establishmentReq, visionReq, goalsReq]).then(res=>{
            if(res[0]?.status === 200){
                let response = res[0]?.data?.data?.data
                if(response?.length === 0){
                    setAddEstablishment(true)
                } else {
                    setAddEstablishment(false)
                    info['establishment_description'] = EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(response[0].description)))
                    info['establishment_img'] = { src: response[0]?.image, loading: false }
                    info['establishment_id'] = response[0]?.id
                }
            }
            if(res[1]?.status === 200){
                let response = res[1]?.data?.data?.data
                if(response?.length === 0){
                    setAddVision(true)
                } else {
                    setAddVision(false)
                    info['vision_description'] = EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(response[0].description)))
                    info['vision_img'] = { src: response[0]?.image, loading: false }
                    info['vision_id'] = response[0]?.id
                }
            }
            if(res[2]?.status === 200){
                let response = res[2]?.data?.data?.data
                if(response?.length === 0){
                    setAddGoals(true)
                } else {
                    setAddGoals(false)
                    info['goals_description'] = EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(response[0].description)))
                    info['goals_img'] = { src: response[0]?.image, loading: false }
                    info['goals_id'] = response[0]?.id
                }
            }
            setFormData(info)
            setLoading(false)
        })
    },[])
    const submit = () => {
        if(!draftToHtml(convertToRaw(formData?.establishment_description.getCurrentContent())) || 
            !draftToHtml(convertToRaw(formData?.vision_description.getCurrentContent())) || 
            !draftToHtml(convertToRaw(formData?.goals_description.getCurrentContent()))){
            toast.error(`Complete All Phases`)
            return
        }
        let establishment_data ={
            type: 'establishment',
            description: draftToHtml(convertToRaw(formData?.establishment_description.getCurrentContent())),
            image: formData?.establishment_img?.src
        }
        let vision_data ={
            type: 'vision',
            description: draftToHtml(convertToRaw(formData?.vision_description.getCurrentContent())),
            image: formData?.vision_img?.src
        }
        let goals_data ={
            type: 'goals',
            description: draftToHtml(convertToRaw(formData?.goals_description.getCurrentContent())),
            image: formData?.goals_img?.src
        }
        setSubmitLoading(true)
        let establishmentReq = addEstablishment ? aboutService.create(establishment_data) : aboutService.update(formData?.establishment_id ,establishment_data)
        let visionReq = addVision ? aboutService.create(vision_data) : aboutService.update(formData?.vision_id ,vision_data)
        let goalsReq = addGoals ? aboutService.create(goals_data) : aboutService.update(formData?.goals_id ,goals_data)

        Promise.all([establishmentReq, visionReq, goalsReq]).then(res=>{
            if(res[0].status && res[1].status && res[2].status){
                toast.success(`Updated Successfully`)
            }
            setSubmitLoading(false)
        })
    }

    const fileHandler = (e, type) => {
        let files = e.target.files
        const filesData = Object.values(files)
 
        if (filesData.length) {
            setFormData({...formData, [`${type}_img`]: {src: "", loading: true}})
            new BaseService().postUpload(filesData[0]).then(res=>{
                if(res?.data?.status){
                    setFormData({...formData, [`${type}_img`]: {src: res.data.url, loading: false}})
                }
                setLoading(false)
            })
        }
    }

    if(loading){
        return <Card className="py-5" style={{height: '300px'}}>
            <Card.Body>
                <Loader />
            </Card.Body>
      </Card>
    }

    return<>
    <Card className="addActivity">
        <Card.Body>
        <AvForm onValidSubmit={submit}>
            <Row className="mb-5 position-relative">
                <Col md={6} className="form-group mb-5">
                    <label className="d-block">{Translate[lang].establishment}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={formData.establishment_description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                setFormData({...formData, establishment_description: e})
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className='mb-5'>
                    <div className='form-group w-100'>
                        <label className="m-0">{Translate[lang]?.image}</label>
                        <div className="image-placeholder mt-5">	
                            <div className="avatar-edit">
                                <input type="file" onChange={(e) => fileHandler(e, "establishment")} id={`imageUpload-establishment`} /> 					
                                <label htmlFor={`imageUpload-establishment`}  name=''></label>
                            </div>
                            <div className="avatar-preview2 m-auto">
                                <div>
                                {!!formData?.establishment_img?.src && 
                                    <img alt='icon'
                                        className='w-100 h-100' 
                                        style={{borderRadius: '30px'}} 
                                        src={formData?.establishment_img?.src}
                                    />}
                                {(!formData?.establishment_img?.src && !formData?.establishment_img?.loading) && 
                                    <img 
                                        src={uploadImg} alt='icon' style={{ width: '80px', height: '80px' }}
                                    />}
                                    {(!formData?.establishment_img?.src && formData?.establishment_img?.loading) && <Loader />}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col md={6} className="form-group mb-5">
                    <label className="d-block">{Translate[lang].vision}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={formData.vision_description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                setFormData({...formData, vision_description: e})
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className='mb-5'>
                    <div className='form-group w-100'>
                        <label className="m-0">{Translate[lang]?.image}</label>
                        <div className="image-placeholder mt-5">	
                            <div className="avatar-edit">
                                <input type="file" onChange={(e) => fileHandler(e, "vision")} id={`imageUpload-vision`} /> 					
                                <label htmlFor={`imageUpload-vision`}  name=''></label>
                            </div>
                            <div className="avatar-preview2 m-auto">
                                <div>
                                {!!formData?.vision_img?.src && 
                                    <img alt='icon'
                                        className='w-100 h-100' 
                                        style={{borderRadius: '30px'}} 
                                        src={formData?.vision_img?.src}
                                    />}
                                {(!formData?.vision_img?.src && !formData?.vision_img?.loading) && 
                                    <img 
                                        src={uploadImg} alt='icon' style={{ width: '80px', height: '80px' }}
                                    />}
                                {(!formData?.vision_img?.src && formData?.vision_img?.loading) && <Loader />}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col md={6} className="form-group mb-5">
                    <label className="d-block">{Translate[lang].goals}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={formData.goals_description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                setFormData({...formData, goals_description: e})
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className='mb-5'>
                    <div className='form-group w-100'>
                        <label className="m-0">{Translate[lang]?.image}</label>
                        <div className="image-placeholder mt-5">	
                            <div className="avatar-edit">
                                <input type="file" onChange={(e) => fileHandler(e, "goals")} id={`imageUpload-goals`} /> 					
                                <label htmlFor={`imageUpload-goals`}  name=''></label>
                            </div>
                            <div className="avatar-preview2 m-auto">
                                <div>
                                {!!formData?.goals_img?.src && 
                                    <img alt='icon'
                                        className='w-100 h-100' 
                                        style={{borderRadius: '30px'}} 
                                        src={formData?.goals_img?.src}
                                    />}
                                {(!formData?.goals_img?.src && !formData?.goals_img?.loading) && 
                                    <img 
                                        src={uploadImg} alt='icon' style={{ width: '80px', height: '80px' }}
                                    />}
                                {(!formData?.goals_img?.src && formData?.goals_img?.loading) && <Loader />}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            
            
            {isExist('owner') && <div className="d-flex justify-content-between">
                <div></div>
                <Button variant="primary" disabled={submitLoading} type="submit">
                    {Translate[lang].submit}
                </Button>
            </div>}
        </AvForm>
        </Card.Body>
    </Card>
    </>
}
export default About;