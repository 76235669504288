import BaseService from "./BaseService";
import http from './HttpService'
import { API_BASE_URL_ENV } from "../jsx/common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/team";
const apiContactUsEndpoint = API_BASE_URL_ENV() + "/contactUs/team";

export default class TeamService extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  createItem(type, data) {
    return http.post(`${apiEndpoint}/${type}`, data);
  }

  getItemList(type, id, params) {
    return http.get(`${apiEndpoint}/${type}/${id}`, {params});
  }

  getContactList(id, params) {
    return http.get(`${apiContactUsEndpoint}/${id}`, {params});
  }

  updateItem(type, id, data) {
    const body = { ...data };
    return http.put(`${apiEndpoint}/${type}/${id}`, body);
  }

  removeItem(type, id, data) {
    if(data || data?.isDeleted){
      return http.delete(`${apiEndpoint}/${type}/${id}`, {data});
    }
    return http.delete(`${apiEndpoint}/${type}/${id}`);
  }
  removeContact(id){
    return http.delete(`${apiContactUsEndpoint}/${id}`);
  }
}
