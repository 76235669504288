export const Rules = [
  { label: "Admin", value: "admin" },
  { label: "Rules", value: "rules" },
  { label: "Owner", value: "owner" },
  { label: "Banners", value: "banners" },
  { label: "Ad Screen", value: "ad_screen" },
  // { label: "Brands", value: "brands" },
  { label: "Users", value: "users" },
  // {label: 'Order', value: 'order'},
  {label: 'Branches and Markets', value: 'branches_and_markets'},
  {label: 'Activities', value: 'activities'},
  // {label: 'Categories', value: 'categories'},
  // {label: 'SubCategories', value: 'sub_categories'},
  // {label: 'Variant', value: 'variant'},
  {label: 'Social Media', value: 'social_media'},
  // {label: 'Control', value: 'control'},
  // {label: 'Currency', value: 'currency'},
  {label: 'Static Pages', value: 'static_pages'},
  {label: 'PR Manager', value: 'pr_manager'},
  {label: 'Website', value: 'website'},
  {label: 'Teams', value: 'teams'},
];
