import { API_BASE_URL_ENV } from "../jsx/common/common";
import http from './HttpService'
import BaseService from "./BaseService";

const apiEndpoint = API_BASE_URL_ENV() + "/contactUs/team";

export default class ContactTeamService extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  getContactList(id, params) {
    return http.get(`${apiEndpoint}/${id}`, {params});
  }
}
